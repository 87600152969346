.container__text--welcome {
  /* background-color: rgba(18, 46, 36, 0.651); */
  /* padding-right: 8rem; */
  /* margin-top: 2%; */
  padding-top: 4em;
  width: 100%;
  height: calc(100vh - 7rem);
}
.home__text--welcome {
  /* position: relative; */
  width: 30rem;
  font-family: "D-DIN";
  /* background-color: rgba(18, 46, 36, 0.651); */
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home__text--second {
  font-family: "D-DIN";
  letter-spacing: 0.4px;
  font-weight: 600;
}
.home__text--security {
  font-family: "Rene Bieder";
  font-size: 2.6em;
  text-shadow: 1px 2px 9px rgba(0, 0, 0, 0.678);
  margin-bottom: 2rem;
  color: #ffc400;
  line-height: 3.5rem;
}
.home__text--button {
  background-color: #ffc400;
  padding: 0.5rem 0rem;
  color: white;
  font-family: "D-DIN-Bold";
  font-size: 2.5em;
  letter-spacing: 0.5px;
  box-shadow: rgba(15, 15, 15, 0.719) 2px 4px 16px 0px,
    rgba(56, 56, 56, 0) -6px -2px 16px 0px;
  border-radius: 8px;
  letter-spacing: 0.6px;
  width: 86%;
  margin-bottom: 18rem;
}
.home__text--button:hover {
  color: white;
  background-color: #ffd13b;
}
@media (max-width: 768px) {
  .container__text--welcome {
    padding-top: 0;
    padding-right: 0;
  }
  .home__text--welcome {
    align-items: center;
    margin-right: 0;
    /* padding: 0; */
  }
  .home__text--security {
    font-size: 3.3em;
  }
  .home__text--button {
    width: calc(100% - 4rem);
    /* margin-top: 17rem; */
    font-size: 2.8em;
    padding: 0.5rem 0rem;
    margin-bottom: 8rem;
  }
}
@media (max-width: 1409px) {
  .container__text--welcome {
    padding-top: 2rem;
    padding-right: 0;
  }
  .home__text--security {
    font-size: 2.8em;
  }
  .home__text--welcome {
    /* padding: 0 0.2rem; */
    margin-right: 0rem;
  }
}
@media (max-width: 750px) {
  .home__text--security {
    font-size: 2.8em;
    line-height: 2rem;
  }
}
