.courses__card {
  height: auto;
  min-height: 28.7rem;
  border: none;
  border-radius: 0;
}
.card-img-top {
  cursor: pointer;
}
.card-body {
  /* background-color: thistle; */
  padding-bottom: 0;
  cursor: pointer;
}
.card-title {
  font-family: "D-DIN-Bold";
  font-size: 22px;
  color: rgb(0, 48, 119);
  margin-bottom: 2rem;
  padding-right: 1rem;
}
.card-description {
  font-family: "D-DIN";
}
.card-action {
  /* background-color: khaki; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.card-time {
  font-family: "D-DIN-Bold";
  font-size: 22px;
  color: #8836f7;
}
.card-button {
  cursor: pointer;
  margin-right: 0.5rem;
  transition: 0.5s;
}
.card-button-right:hover {
  -webkit-transform: translate3d(15%, 0, 0);
  transform: translate3d(15%, 0, 0);
}
.card-button-left:hover {
  -webkit-transform: translate3d(-15%, 0, 0);
  transform: translate3d(-15%, 0, 0);
}
.card-button img {
  width: 1.8rem;
}
