.back {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
  width: 2rem;
  height: 2rem;
  margin-top: 1rem;
  margin-left: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.back img {
  width: 80%;
}
.back img:hover {
  width: 70%;
}
.inscription__background {
  background-image: url("../../../assets/images/Img_formulario.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  padding: 0;
}
.inscription__form__container {
  font-family: "D-DIN-Bold";
  padding-top: 1.5rem;
  overflow-y: scroll;
  /* background-color: #9798c4; */
  height: 100vh;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inscription__form__container h5 {
  color: #8836f7;
  margin-bottom: 0;
}
.inscription__form__container h2 {
  font-family: "Rene Bieder";
  color: #ffc400;
}
.inscription__inputs {
  /* background-color: #ffc400; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  width: 25rem;
}
.division {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid #5f5f5f83;
}
.inscription__inputs h4 {
  font-size: 1.4375em;
  color: #8836f7;
  /* width: 100%; */
}
.inscription__form--button {
  background-color: #ffc400;
  padding: 0.4rem 0rem;
  color: white;
  font-family: "D-DIN-Bold";
  font-size: 1.9em;
  letter-spacing: 0.5px;
  box-shadow: rgba(15, 15, 15, 0.507) 2px 4px 7px 0px,
    rgba(56, 56, 56, 0) -6px -2px 16px 0px;
  border-radius: 8px;
  letter-spacing: 0.6px;
}
.inscription__form--button:hover {
  background-color: #e9b713;
  color: white;
}
.form__terms {
  font-family: "D-DIN";
  margin-top: 1.4rem;
  color: #5f5f5faf;
  font-size: 14px;
}
.form__terms.link {
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #5f5f5faf;
}
.form__terms.link:hover {
  text-decoration: none;
}
select.form-control {
  border-radius: 3px;
  height: 2.8rem;
  color: #5f5f5faf;
}
@media (max-width: 768px) {
  .inscription__form__container {
    font-size: 14px;
  }
  .inscription__inputs {
    width: 90%;
  }
  .inscription__form__container h5 {
    font-size: 19px;
  }
  .inscription__form__container h2 {
    font-size: 25px;
  }
}
@media (min-width: 1920px) {
  .inscription__form__container {
    overflow-y: auto;
  }
}
