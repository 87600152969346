.title-modal {
  font-family: "D-DIN";
  letter-spacing: 0px;
  color: #aa8e73;
  opacity: 1;
  font-style: normal;
  font-size: 20px;
  margin-top: 1rem;
}
.message-modal {
  font-family: "D-DIN";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  margin-top: 1.5rem;
}
.modal-content {
  border-radius: 8px !important;
}
.modal-lg.modal-form {
  max-width: 35rem;
}
.modal-footer {
  border-top: 0 none;
  padding-bottom: 2rem;
  align-items: center;
  align-content: center;
}
.btn-modal {
  background-color: #ffc400;
  padding: 8px 1.8rem;
  color: white;
  font-family: "D-DIN-Bold";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: rgba(15, 15, 15, 0.507) 2px 4px 7px 0px,
    rgba(56, 56, 56, 0) -6px -2px 16px 0px;
  border-radius: 8px;
  letter-spacing: 0.6px;
  border: none;
}
.btn-modal:hover {
  background-color: #e9b713;
  color: white;
}
