.banner {
  position: relative;
  height: auto;
  width: 100%;
  background-image: url("../../../assets/images/banner_web.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 10rem;
  z-index: 1;
  font-size: 16px;
}
.banner h3 {
  font-family: "D-DIN";
  font-size: 1.9375em;
  text-shadow: 0px 1px 30px rgba(0, 0, 0, 0.377);
  margin-bottom: 1rem;
  color: rgb(0, 0, 133);
}
.banner h2 {
  font-family: "D-DIN-Bold";
  font-size: 2.8125em;
  color: white;
  text-shadow: 0px 1px 7px rgba(0, 0, 0, 0.404);
  margin-top: 2.1rem;
  line-height: 2.65rem;
}
.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 217, 0, 0.555);
  z-index: -1;
}
@media (max-width: 768px) {
  .banner {
    background-image: url("../../../assets/images/bannner_mobile.png");
    background-position: center;
    background-repeat: inherit;
    background-size: contain;
    padding: 2.8rem 2.5rem;
    font-size: 10.5px;
  }
  .banner h2 {
    margin-top: 0;
    line-height: 1.95rem;
  }
}
