.courses {
  background-color: #1b1464;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.courses__title {
  font-family: "Rene Bieder";
  font-size: 60px;
  color: white;
  margin: 2.5rem 0;
  text-shadow: 1px 4px 2px rgba(0, 0, 0, 0.815);
}
