.header__home {
  /* background-color: rgba(250, 235, 215, 0.767); */
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 2.5rem; */
}
.navbar > a {
  width: 3rem;
  margin: 0 1rem;
  background-color: #9b53ff;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .header__home {
    height: 6rem;
  }
  .navbar > a {
    width: 2.5rem;
    margin: 0 0.4rem;
    /* margin-top: -1rem; */
  }
}
