.card__collapse {
  background-color: white;
  width: 100%;
  /* height: 5rem; */
  max-height: 20rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  padding: 1.1rem 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.322) 1.95px 1.95px 10.6px;
  /* transition: max-height 1.15s ease-out; */
  overflow-y: hidden;
  /* transition: max-height 0.7s linear;*/
}
.card__collapse.expanded {
  max-height: 20rem;
  height: auto;
  /* animation: slideDown 0.5s; */
}
.card__collapse--title {
  /* background-color: thistle; */
  width: 87%;
  color: #1b1464;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: initial;
  line-height: 24px;
}
.card__collapse--title div {
  font-family: "D-DIN-Bold";
  font-size: 19px;
}
.card__collapse--title p {
  font-family: "D-DIN";
  font-size: 16px;
  margin-top: 0.9rem;
  line-height: 19px;
}
.card__collapse--icon {
  margin-left: auto;
  margin-right: 0.5rem;
  min-height: 100%;
  cursor: pointer;
}
.card__collapse--icon-expanded {
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 0.5rem;
  margin-top: 0.75rem;
  cursor: pointer;
}
.card__collapse img {
  width: 22px;
}
.card__collapse--time {
  font-family: "D-DIN-Bold";
  font-size: 22px;
  color: #8836f7;
}
@keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 19rem;
  }
}
