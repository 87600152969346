.container__home {
  height: 100vh;
  min-height: 42rem;
  width: 100%;
  background-image: url("../../../assets/images/Fondo_web.png");
  /* background-color: aquamarine; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 20px;
}

@media (max-width: 768px) {
  .container__home {
    background-image: url("../../../assets/images/Fondo_mobile.png");
    font-size: 12px;
  }
}
